import Config from "@/vendor/config"
import Request from "@/api/request.js"

// 商品列表
const productIndex = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/product/index', {params})
}
// 商品分类
const productClassifyIndex = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, '/api/product/classify', {params})
}
// 商品详情
const productDetail = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/product/detail', {params})
}



// 用户购物车列表
const productCartIndex = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/product/cart/index', {params})
}
// 加入购物车
const productCartCreate = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/product/cart/create', params)
}
// 加购物车数量
const productCartIncNum = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/product/cart/incNum', params)
}
// 减购物车数量
const productCartDecNum = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/product/cart/decNum', params)
}
// 删除购物车商品
const productCartDelete = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/product/cart/delete', params)
}
// 购物车下单页
const productCartDetail = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/product/cart/detail', {params})
}
// 购物车订单支付
const productCartOrder = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/product/cart/order', params)
}






// 用户收货地址列表
const productAddressIndex = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/product/address/my', {params})
}
// 设置默认地址
const productAddressSetDefault = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/product/address/setDefault', params)
}
// 添加收货地址
const productAddressCreate = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/product/address/create', params)
}
// 删除收货地址
const productAddressDelete = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/product/address/delete', params)
}
// 修改收货地址
const productAddressUpdate= (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/product/address/update', params)
}
// 收货地址详情
const productAddressDetail= (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/product/address/detail', {params})
}


// 立即购买++商品
const productGetPayInfo = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/product/order/getPayInfo', params)
}

// 下单列表
const productOrderIndex = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/product/order/index', params)
}
// 立即购买  支付
const productOrderCreate = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/product/order/create', params)
}


// 我的订单
const productOrderList= (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/product/order/records', {params})
}
// 订单详情
const productOrderDetail= (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.get, 'api/product/order/detail', {params})
}

// 查询是否支付成功
const productOrderQuery = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/product/order/query', params)
}
// 用户确认收货
const productOrderReceive = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/product/order/receive', params)
}

export default {
	productIndex,
	productDetail,
	
	
	productCartIndex,
	productCartCreate,
	productCartIncNum,
	productCartDecNum,
	productCartDelete,
	productCartDetail,
	productCartOrder,
	
	
	productAddressIndex,
	productAddressSetDefault,
	productAddressCreate,
	productAddressDelete,
	productClassifyIndex,
	productAddressUpdate,
	productAddressDetail,
	
	productGetPayInfo,
	productOrderIndex,
	productOrderCreate,
	
	productOrderList,
	productOrderDetail,
	productOrderQuery,
	productOrderReceive
}
